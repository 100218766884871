#root {
    height: 100%;
    background-color: #F2F6FF;

}

@font-face {
    font-family: 'PingFangSC-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('PingFangSC-Regular'), url('./assets/fonts/PingFangSC-Regular.woff2') format('woff');
}

@font-face {
    font-family: 'PingFangSC-Semibold';
    font-style: normal;
    font-weight: 600;
    src: local('PingFangSC-Semibold'), url('./assets/fonts/PingFangSC-Semibold.woff2') format('woff');
}






// @font-face {
//     font-family: 'SourceHanSansCN-ExtraLight';
//     font-style: normal;
//     font-weight: normal;
//     src: local('SourceHanSansCN-ExtraLight'), url('./assets/fonts/SourceHanSansCN-ExtraLight.woff') format('woff');
// }
// @font-face {
//     font-family: 'SourceHanSansCN-Light';
//     font-style: normal;
//     font-weight: normal;
//     src: local('SourceHanSansCN-Light'), url('./assets/fonts/SourceHanSansCN-Light.woff') format('woff');
// }
// @font-face {
//     font-family: 'SourceHanSansCN-Normal';
//     font-style: normal;
//     font-weight: normal;
//     src: local('SourceHanSansCN-Normal'), url('./assets/fonts/SourceHanSansCN-Normal.woff') format('woff');
// }
@font-face {
    font-family: 'SourceHanSansCN-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SourceHanSansCN-Regular'), url('./assets/fonts/SourceHanSansCN-Regular.woff') format('woff');
}
@font-face {
    font-family: 'SourceHanSansCN-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SourceHanSansCN-Medium'), url('./assets/fonts/SourceHanSansCN-Medium.woff') format('woff');
}
@font-face {
    font-family: 'SourceHanSansCN-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SourceHanSansCN-Bold'), url('./assets/fonts/SourceHanSansCN-Bold.woff') format('woff');
}
@font-face {
    font-family: 'SourceHanSansCN-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('SourceHanSansCN-Heavy'), url('./assets/fonts/SourceHanSansCN-Heavy.woff') format('woff');
}

 body{
    font-family: 'SourceHanSansCN-Regular';
 }  





@brand-primary: red;@color-text-base: #333;